import { render, staticRenderFns } from "./LatestArticles.vue?vue&type=template&id=2bfdc149&"
import script from "./LatestArticles.vue?vue&type=script&lang=ts&"
export * from "./LatestArticles.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SharedHeader: require('/vercel/path0/components/shared/Header.vue').default,Container: require('/vercel/path0/components/Container.vue').default,ArticleList: require('/vercel/path0/components/ArticleList.vue').default,SharedButton: require('/vercel/path0/components/shared/Button.vue').default})
