
import {
  Component,
  Prop,
  Vue,
} from 'nuxt-property-decorator'
import {
  ArticleRelationResponseCollection,
  ComponentBlocksLatestArticles,
} from '~/graphql/generated'
import latestArticlesQuery from '~/graphql/queries/latestArticles.graphql'


@Component
export default class LatestArticles extends Vue {
  latestArticles: ArticleRelationResponseCollection = {
    data: [],
  }

  @Prop() readonly data!: ComponentBlocksLatestArticles

  async fetch() {
    this.latestArticles = await this.$apolloProvider.defaultClient.query({
      query: latestArticlesQuery,
    }).then((response) => response.data.articles)
  }

  isFirst(index: number) {
    return index === 0
  }

  isLast(index: number) {
    if (!this.latestArticles) {
      return false
    }
    return index === this.latestArticles.data.length - 1
  }
}
